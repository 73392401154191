.app-ot-rate-view {
	height: 100%;
	padding: 0 35px;

	@include max-width($scr-md) {
		padding: 0 15px;
	}

	.ot-rate-view {
		padding-bottom: 25px;

		&__title {
			margin: 0 0 10px;
			font-size: $text-size-xl;
		}

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.app-button {
				width: auto;
				margin: 0 0 15px;
				padding: 10px 15px;
				border: 1px solid $c-secondary;
				background-color: transparent !important;
				display: flex;
				flex-direction: row-reverse;

				.app-button__label {
					color: $c-secondary;
					white-space: nowrap;
				}

				&:disabled {
					.app-button__label {
						color: $c-white;
					}
				}

				.app-icon {
					width: 12.5px;
					height: 12.5px;
				}

				&__label {
					margin-right: 5px;
					font-size: $text-size-xs;
				}
			}
		}

		&__divider {
			margin: 30px 0;
			border-bottom: 1px solid $c-disabled;
		}

		&__input-title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}

		&__form {
			width: 100%;
			max-width: 1280px;
		}

		&__container {
			margin: 15px 0;
			display: flex;
			gap: 15px;

			.app-toggle-switch {
				padding: 10px 15px;
				flex-direction: row-reverse;
				gap: 10px;
			}
		}

		&__box {
			flex: 1;
			padding: 25px 15px;
			border-radius: 8px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
		}

		&__wrapper {
			flex: 1;
		}

		&__extra-benefit {
			flex: 1;

			.app-input {
				margin: 20px 0;
			}
		}

		&__box-body {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			gap: 20px 35px;

			.app-input {
				//
				@include min-width($scr-sm) {
					width: calc(50% - 17.5px);
				}
			}
		}

		&__input {
			//
			.app-input {
				width: 100%;
			}
		}

		&__input-section {
			display: flex;
			flex-direction: row-reverse;
		}

		&__remove-button {
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}

		&__input-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__input-switch {
			display: flex;
			align-items: center;
			gap: 8px;
		}

		&__button-container {
			width: 100%;
			max-width: 350px;
			margin-left: auto;
			display: flex;
			gap: 15px;

			.app-button {
				flex: 1;

				&--outline {
					width: auto;
					border: 0;
					background-color: transparent;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}

		&__inline-container {
			width: 100%;
			display: flex;
			align-items: flex-end;
			gap: 15px;
		}

		.MuiSwitch-root {
			width: 30px;
			height: 20px;
			margin: 5px 0;
			padding: 0;
			border-radius: 100px;

			.MuiSwitch-switchBase {
				padding: 3px;
			}

			.Mui-checked {
				color: $c-primary;
				transform: translateX(10px);

				+ .MuiSwitch-track {
					opacity: 1;
					background-color: $c-primary;
				}
			}

			.MuiSwitch-thumb {
				width: 14px;
				height: 14px;
				color: $c-white;
			}
		}
	}
}
