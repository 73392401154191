.app-scheduled-attendance-employee-calendar {
	width: 100%;

	.scheduled-attendance-employee-calendar {
		//
		.app-calendar-schedule {
			//
			.MuiPaper-root {
				//
				// .MuiTableCell-head {
				// 	//
				// 	&:nth-child(4) {
				// 		padding: 0 50px 0 0;
				// 		text-align: end;
				// 	}

				// 	&:nth-child(5) {
				// 		padding: 0 0 0 40px;
				// 		text-align: start;
				// 	}
				// }

				.MuiTableCell-body {
					//
					&:nth-child(4),
					&:nth-child(5) {
						padding: 10px 0 10px 20px;
					}

					&:not(:first-child) {
						text-align: left;
					}
				}
			}

			.table {
				//
				&__delete-group {
					width: 100%;
					height: 100%;
					margin: 0 0 20px;
					display: flex;
					flex-direction: column;
					align-self: flex-end;
					gap: 10px;
				}

				&__delete {
					&:nth-child(1){
						margin-top: 10px;
					}
				}

				&__action,
				&__delete {
					width: 100%;
					height: 100%;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 30px;
					}
				}
			}
		}
	}
}

.scheduled-attendance-employee-calendar-menu {
	//
	.MuiPaper-root {
		border-radius: 8px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);

		.MuiMenuItem-root {
			color: $c-grey;
			font-size: $text-size-s;

			img {
				width: 20px;
				height: 20px;
				margin: 0 5px 0 0;
			}
		}
	}
}

.scheduled-attendance-employee-input {
	.app-input-time {
		margin-top: -1px;
		margin-bottom: 5px;
	}

	.app-select-input {
		margin-bottom: 0px;
	}
}