.app-calendar-schedule {
	//
	.calendar-schedule {
		width: 100%;
		margin: 0 0 10px;
		display: flex;
		flex-direction: row-reverse;
		text-align: right;

		&__title {
			width: 100%;
			margin: 0;
			color: $c-primary;
			font-size: $text-size-xl;

			span {
				color: $c-primary;
				font-size: $text-size-xl;
				font-weight: $text-weight-strong;
			}
		}

		&__button-container {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: end;
			align-items: flex-end;
			gap: 10px;

			@include max-width($scr-xs) {
				flex-direction: column;
			}
		}

		&__edit-button {
			width: 100%;
			max-width: 100px;
			padding: 8px 20px;
			flex-direction: row-reverse;

			.app-icon {
				width: 20px;
				height: 20px;
			}
		}

		&__expandable {
			display: flex;
			align-items: center;
		}
		&__expandable-button {
			border: 0;
			cursor: pointer;
			background-color: transparent;
			transform: rotate(90deg);
			&--expanded {
				transform: rotate(270deg);
			}
		}
		&__expandable-icon {
			width: 20px;
			height: 20px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url("../../../assets/images/chevron-right-light-blue-icon.svg");
		}

		&__edit-buttons {
			width: 100%;
			max-width: 255px;
			display: flex;
			flex-direction: row;

			.app-button {
				padding: 10px 20px;

				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}

		&__chevron {
			display: flex;
			flex-direction: row;
			gap: 10px;

			.app-button {
				max-width: 35px;
				margin: 0;
				padding: 5px;
				border-radius: 10px;
				border: 1px solid $c-primary;
				background-color: transparent;
			}
		}

		&__left-chevron {
			//
			.app-icon__i {
				transform: rotate(180deg);
			}
		}

		&__table {
			//
			.calendar-row {
				//
				&--weekend {
					background-color: $c-lighter-grey;

					&:hover {
						background-color: $c-lighter-grey;
					}
				}
			}

			.MuiInputBase-root {
				width: 100%;
				max-width: 250px;
			}

			.MuiPaper-root {
				box-shadow: 0px 0px 10.7px 0px rgba(163, 166, 172, 0.2);

				.MuiTableCell-head {
					padding: 15px 20px;
					background-color: $c-header-blue;
					border: 0;

					&:not(:first-child) {
						text-align: center;
					}

					.MuiButtonBase-root {
						min-width: inherit;
						padding: 0;
						white-space: nowrap;
						text-transform: capitalize;
					}
				}

				.MUIDataTable-responsiveBase {
					overflow: visible;
				}

				.MuiTableCell-root {
					//
					div {
						color: $c-grey;
						font-size: $text-size-s;
						text-align: center;
					}
				}

				.MuiTableCell-body {
					padding: 10px 20px;
					border-bottom: 1px solid $c-header-blue;

					&:first-child {
						width: 200px;
						background-color: $c-background;
						border-right: 1px solid $c-header-blue;
					}

					&:not(:first-child) {
						text-align: center;
					}
				}
			}
		}
	}
}
