.page-hr-view-holidays {
	//
	.hr-view-holidays {
		//
		&__title {
			margin: 15px 0;
			font-size: $text-size-xl;
		}

		.app-table {
			margin: 15px 0;

			.table {
				//
				&__action {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				&__wrapper {
					display: flex;
				}

				&__states {
					max-width: 200px;
					color: $c-grey;
					font-size: $text-size-s;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}
			}
		}
	}
}

.hr-view-holidays-table-menu {
	//
	.MuiPaper-root {
		border-radius: 8px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);

		.MuiMenuItem-root {
			color: $c-grey;
			font-size: $text-size-s;

			img {
				width: 20px;
				height: 20px;
				margin: 0 5px 0 0;
			}
		}
	}
}
