.page-view-overtime {
	//
	.view-overtime {
		&__title {
			margin: 0 0 10px;
			font-size: $text-size-xl;
		}

		&__id {
			margin: 0 0 10px;
			font-size: $text-size-l;
		}

		&__time {
			margin: 0 0 10px;
			font-size: $text-size-l;
			color: $c-secondary;
		}

		&__header-container {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			margin-bottom: 20px;
		}

		&__header {
			display: flex;
			flex-direction: column;

			&--right {
				display: flex;
				flex-direction: column;
				text-align: right;
			}
		}

		&__add-button {
			.app-button {
				max-width: 150px;
				padding: 10px 20px;
				flex-direction: row-reverse;
			}
		}

		.app-table-filter-header {
			margin-bottom: 20px;
		}

		.app-table {
			margin: 15px 0;

			.table {
				&__action {
					width: 100%;
					height: 28px;
					cursor: pointer;
					background-color: transparent;
					border: 0;
				}

				.MuiTableCell-head {
					&:last-child {
						text-align: center;
					}
				}
			}
		}
	}
}

.view-overtime-filter {
	width: 100%;
	min-width: 500px;
	display: flex;
	flex-direction: column;
	
	@include max-width($scr-xs) {
		min-width: 100%;
	}
	
	&__date-inputs {
		display: flex;
		flex-direction: row;
		gap: 15px;
		width: 100%;
		
		.app-input-date {
			flex: 1;
			min-width: 180px;
			display: inline-block;
		}
	}
	
	&__instruction {
		color: $c-light-grey;
		font-size: $text-size-s;
		margin: 10px 0;
	}
}

.view-overtime-table-menu {
	//
	.MuiPaper-root {
		border-radius: 8px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);

		.MuiMenuItem-root {
			color: $c-grey;
			font-size: $text-size-s;

			img {
				width: 20px;
				height: 20px;
				margin: 0 5px 0 0;
			}
		}
	}
}