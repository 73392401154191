.app-tabs {
	margin-bottom: 15px;

	.tabs {
		&__header-container {
			position: relative;
		}

		&__header-wrapper {
			position: relative;

			&::after {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 1px;
				content: "";
				display: block;
				width: 100%;
				height: 1px;
				border-radius: 5px;
				background-color: $c-light-grey;
				z-index: 1;
			}
		}

		&__header {
			&::-webkit-scrollbar {
				display: none;
			}

			-ms-overflow-style: none;
			scrollbar-width: none;

			display: flex;
			align-items: center;
			flex-direction: row;
			margin-bottom: 16px;
			scroll-behavior: smooth;
			overflow-x: auto;
			-webkit-overflow-scrolling: touch;
			position: relative;
			white-space: nowrap;
		}

		&__body {
			margin-top: 20px;
		}

		&__scroll-btn {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 100;
			background: transparent;
			border: none;
			cursor: pointer;
			padding: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 1;
			transition: opacity 0.3s ease;

			&:disabled {
				opacity: 0;
				pointer-events: none;
			}

			img {
				transition: filter 0.3s ease;
				width: 25px;
				height: 25px;
			}

			&:hover {
				img {
					filter: invert(57%) sepia(75%) saturate(2160%) hue-rotate(149deg) brightness(96%) contrast(101%);
				}
			}

			&--left {
				left: -30px;
			}

			&--right {
				right: -30px;
			}
		}

		.tab {
			padding: 10px 15px;
			border: 0;
			background-color: transparent;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			align-items: center;
			flex-shrink: 0;

			&__label {
				margin: 0 10px;
				color: $c-light-grey;
				font-size: $text-size-s;
			}

			&__icon {
				width: 30px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&__number {
				width: 25px;
				height: 25px;
				border-radius: 25px;
				color: $c-white;
				font-size: $text-size-s;
				font-weight: $text-weight-semibold;
				background-color: $c-light-grey;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&--active {
				position: relative;
				z-index: 99;

				&:after {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					content: "";
					display: block;
					width: 100%;
					height: 3px;
					border-radius: 3px;
					background-color: $c-tertiary;
				}

				.tab__label {
					color: $c-tertiary;
					font-weight: $text-weight-semibold;
				}

				.tab__number {
					background-color: $c-tertiary;
				}
			}

			@include max-width($scr-sm) {
				display: none;
			}
		}

		&__select-input {
			.MuiFormControl-root {
				.MuiOutlinedInput-notchedOutline {
					border-radius: 8px;
					border: 1px solid $c-primary;
				}

				.MuiOutlinedInput-input {
					color: $c-primary;
				}

				.MuiSelect-icon {
					color: $c-primary;

					path {
						stroke: $c-primary;
					}
				}
			}

			@include min-width($scr-sm) {
				display: none;
			}
		}
	}
}
