.app-overwrite-hold-on-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.overwrite-hold-on-modal {
		width: 100%;
		max-width: 700px;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;
		max-height: 90vh;
		overflow-y: auto;

		&__title {
			margin: 20px 0;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-l;
			font-weight: bold;
		}

		&__description {
			margin: 0;
			color: $c-grey;
			text-align: center;
			margin-bottom: 10px;

			strong {
				color: $c-dark-grey;
				font-weight: 600;
			}
		}

		&__loading {
			text-align: center;
			padding: 15px;
			color: $c-grey;
		}

		&__table-container {
			margin: 20px 0;
			border-radius: 4px;
			overflow: auto;
			border: 1px solid $c-light-grey;
			max-height: 300px;
			-webkit-overflow-scrolling: touch;

			&::-webkit-scrollbar {
				width: 8px;
				height: 8px;
			}

			&::-webkit-scrollbar-track {
				background: #f1f1f1;
				border-radius: 4px;
			}

			&::-webkit-scrollbar-thumb {
				background: #ccc;
				border-radius: 4px;

				&:hover {
					background: #aaa;
				}
			}

			&::-webkit-scrollbar-button {
				display: none;
			}

			scrollbar-width: thin;
			scrollbar-color: #ccc #f1f1f1;
		}

		&__table {
			width: 100%;
			border-collapse: collapse;

			th,
			td {
				padding: 10px 12px;
				text-align: left;
				border-bottom: 1px solid $c-light-grey;
				font-size: $text-size-s;
				border-right: 1px solid $c-light-grey;

				&:first-child {
					min-width: 150px;
				}
				&:nth-child(2) {
					min-width: 120px;
				}
				&:last-child {
					min-width: 150px;
					border-right: none;
				}
			}

			th {
				background-color: $c-light-grey;
				font-weight: 600;
				color: $c-dark-grey;
				position: sticky;
				top: 0;
				z-index: 1;
			}

			tbody tr td {
				border-bottom: 1px solid $c-light-grey;
			}
		}

		&__button-container {
			margin: 20px 0;
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
			gap: 15px;

			@media (max-width: 576px) {
				flex-direction: column;
			}

			.app-button {
				flex: 1;
				min-width: 120px;

				&--outline {
					border: 1px solid $c-light-grey;
					background-color: transparent;

					&:hover {
						background-color: rgba($c-light-grey, 0.2);
					}
				}
			}
		}
	}
}
