.app-add-customer-cost-centre-modal {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.add-customer-cost-centre-modal {
		width: 100%;
		max-height: 90%;
		max-width: 950px;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__title {
			color: $c-primary;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}

		&__form {
			width: 100%;
			overflow: scroll;
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* Internet Explorer 10+ */

			.app-table-filter-header {
				margin: 20px 0 10px;

				.app-input {
					margin: 0;

					.MuiOutlinedInput-notchedOutline {
						border-radius: 5px;
						border: 1px solid $c-light-grey;
					}
				}
			}

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}

		&__description {
			margin: 0 0 10px;
		}

		&__button-container {
			max-width: 320px;
			margin: 15px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}

		.table-filter-header {
			//
			.app-input {
				//
				.MuiOutlinedInput-notchedOutline {
					border: 1px solid $c-border;
				}
			}
		}

		.app-table {
			margin: 15px 0 0 0;

			.table {
				//
				&__wrapper{
					display: flex;
				}

				&__states {
					max-width: 150px;
					color: $c-grey;
					font-size: $text-size-s;
					white-space: nowrap; 
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}
			}
		}
		
	}
}
