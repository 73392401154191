.app-customer-site-details {
	width: 100%;
	max-width: $scr-w-md;

	.customer-site-details {
		//
		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__last-update {
			margin: 0;
			color: $c-black;
			font-size: $text-size-s;

			span {
				color: $c-light-grey;
				font-size: $text-size-s;
			}
		}

		&__title {
			color: $c-black;
			font-size: $text-size-xl;
		}

		&__container {
			padding: 25px 0;
			margin-bottom: 25px;
			background-color: $c-white;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
		}

		&__label {
			margin: 0 25px 15px;
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
		}

		&__row {
			padding: 0 25px;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;

			&--divider {
				margin-bottom: 25px;
				border-bottom: 1px solid $c-border;
			}

			.app-input,
			.app-select-input,
			.app-input-multi-select {
				width: calc(50% - 20px);
			}

			.app-input--multiline {
				width: 100%;
			}

			@include max-width($scr-xs) {
				flex-direction: column;

				.app-input,
				.app-select-input,
				.app-input-multi-select {
					width: 100%;
				}
			}
		}

		&__checkbox-tooltip-wrapper {
			width: calc(50% - 20px);
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 20px;

			.app-radio-input__faq-icon {
				margin-left: 10px;
				cursor: pointer;
			}

			@include max-width($scr-xs) {
				width: 100%;
			}
		}

		&__table {
			padding: 0 25px;
			margin-bottom: 25px;
			border-bottom: 1px solid $c-border;
		}

		&__button-container {
			max-width: 320px;
			margin: 15px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
